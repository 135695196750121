import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/vercel/path0/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsent"] */ "/vercel/path0/src/components/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageDialog"] */ "/vercel/path0/src/components/ui-custom/dialogs/page-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SheetPanel"] */ "/vercel/path0/src/components/ui-custom/dialogs/SheetPanel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinguiClientProvider"] */ "/vercel/path0/src/i18n/LinguiClientProvider.tsx");

"use client"

import { CookieIcon } from "lucide-react"
import { Button } from "./ui/button"
import { useEffect, useState } from "react"
import { cn } from "@/utils/utils"
import { ModalProse } from "@/app/[lang]/login/ModalProse"

export function CookieConsent({
  onAcceptCallback = () => {},
  onDeclineCallback = () => {},
  contentHtmlPolitiqueDeCookies = "",
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [hide, setHide] = useState(false)

  const accept = () => {
    setIsOpen(false)
    document.cookie = "cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT"
    setTimeout(() => {
      setHide(true)
    }, 700)
    onAcceptCallback()
  }

  const decline = () => {
    setIsOpen(false)
    setTimeout(() => {
      setHide(true)
    }, 700)
    onDeclineCallback()
  }

  useEffect(() => {
    try {
      setIsOpen(true)
      if (document.cookie.includes("cookieConsent=true")) {
        setIsOpen(false)
        setTimeout(() => {
          setHide(true)
        }, 700)
      }
    } catch (e) {
      console.error("Error: ", e)
    }
  }, [])

  return (
    <div
      className={cn(
        "fixed z-[20] bottom-0 right-0 sm:right-4 sm:bottom-4 w-full sm:max-w-md duration-700",
        !isOpen
          ? "transition-[opacity,transform] translate-y-8 opacity-0"
          : "transition-[opacity,transform] translate-y-0 opacity-100",
        hide && "hidden",
      )}
    >
      <div className="dark:bg-card bg-background rounded-md m-3 border border-border shadow-lg">
        <div className="grid gap-2">
          <div className="border-b border-border h-14 flex items-center justify-between p-4">
            <h1 className="text-lg font-medium">Nous utilisons des cookies</h1>
            <CookieIcon className="h-[1.2rem] w-[1.2rem]" />
          </div>
          <div className="p-4">
            <p className="text-sm font-normal text-start">
              Nous utilisons des cookies pour vous garantir la meilleure expérience sur
              notre site web. Pour plus d&apos;informations sur la façon dont nous
              utilisons les cookies, veuillez consulter notre politique de cookies.
              <br />
              <br />
              <span className="text-xs">
                En cliquant sur &quot;
                <span className="font-medium opacity-80">Accepter</span>
                &quot;, vous acceptez notre utilisation des cookies.
              </span>
              <br />
              <br />
              <ModalProse title="En savoir plus." text={contentHtmlPolitiqueDeCookies} />
            </p>
          </div>
          <div className="flex gap-2 p-4 py-5 border-t border-border dark:bg-background/20">
            <Button variant="forecastOutline" onClick={decline} className="w-full">
              Refuser
            </Button>
            <Button variant="forecast" onClick={accept} className="w-full">
              Accepter
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

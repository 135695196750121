import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"

export function ModalProse({ text, title }: { text: string; title: string }) {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <span className="cursor-pointer underline underline-offset-4 hover:text-primary">
          {title}
        </span>
      </AlertDialogTrigger>
      <AlertDialogContent className="w-[90vw] max-w-[90vw] sm:w-[767px] sm:max-w-[767px]">
        <div
          className="prose prose-neutral max-h-[80dvh] overflow-y-auto custom-scrollbar"
          style={{ maxWidth: "100%" }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <AlertDialogFooter>
          <AlertDialogCancel className="bg-forecast-blue-readable text-white">
            Fermer
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
